<template>
  <AppForm
    class="login-page"
    :controller="validationController"
    #default="{ formValid }"
    @submit.native.prevent="submitFrom"
  >
    <h1 class="form-title">
      {{ $t('login__title') }}
    </h1>

    <AppFormItem
      :controller="validationController"
      prop="login"
      :label="$t('common__email')"
      #default="{ validate, clearValidation }"
    >
      <ElInput
        v-model="form.login"
        v-debounce:500ms="validate"
        :placeholder="$t('common__email_placeholder')"
        :readonly="loading"
        autocomplete="on"
        type="email"
        autofocus
        @blur="validate"
        @focus="clearValidation"
        @input="clearValidation"
        @input.native="e => $isReplacement(e) && validate()"
      >
        <template #prefix>
          <div class="form-icon">
            <FontAwesome icon="envelope" />
          </div>
        </template>
      </ElInput>
    </AppFormItem>

    <AppFormItem
      :controller="validationController"
      prop="password"
      :label="$t('common__password')"
      #default="{ validate, clearValidation }"
    >
      <div class="form-item-controls">
        <RouterLink class="el-link" :to="{ name: 'reset-password' }">
          {{ $t('login__forgot_password') }}</RouterLink
        >
      </div>

      <ElInput
        v-model="form.password"
        v-debounce:500ms="validate"
        :placeholder="$t('common__password_placeholder')"
        :readonly="loading"
        autocomplete="on"
        type="password"
        @blur="validate"
        @focus="clearValidation"
        @input="clearValidation"
      >
        <template #prefix>
          <div class="form-icon">
            <FontAwesome icon="lock-alt" />
          </div>
        </template>
      </ElInput>
    </AppFormItem>

    <AppFormItem class="form-controls">
      <div class="login-page__controls">
        <ElButton type="primary" native-type="submit" :disabled="!formValid" :loading="loading">
          {{ $t('common__login') }}
        </ElButton>

        <VueRecaptcha
          v-if="recaptchaData.enabled && recaptchaData.key"
          ref="recaptcha"
          size="invisible"
          loadRecaptchaScript
          @verify="login"
          @expired="submitFrom"
          :sitekey="recaptchaData.key"
        ></VueRecaptcha>

        <RouterLink
          v-if="registrationAvailable"
          :to="{ name: 'registration' }"
          class="el-link login-page__control"
        >
          {{ $t('common__register') }}
        </RouterLink>
      </div>
    </AppFormItem>
  </AppForm>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { FormValidatorFactory } from '@corefy/vue-validation/services/FormValidator';
import { FormValidationController } from '@corefy/vue-validation/services/FormValidationController';
import { ValidatorBuilderFactory } from '@/shared/validation/services/ValidatorBuilder';
import { ElementUiValidationControllerFactory } from '@/shared/element-ui/services/ElementUiValidationController';
import { LoginStore } from '@/shared/store/modules/LoginStore';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import VueRecaptcha from 'vue-recaptcha';
import RecaptchaType from 'vue-recaptcha/types';

interface Form {
  login: string;
  password: string;
}

@Component({ components: { VueRecaptcha } })
export default class LoginPage extends Vue {
  $refs!: {
    recaptcha: RecaptchaType;
  };

  private form: Form = {
    login: '',
    password: '',
  };

  private validationController: FormValidationController<Form> = this.createController<Form>({
    form: this.form,
    validator: this.formValidator({
      login: this.validator().setRequired(true).useCustom.emailValidator().getResult(),
      password: this.validator().setRequired(true).getResult(),
    }),
  });

  private recaptchaData = this.appDataService.appData.recaptcha;

  private get loading() {
    return this.loginStore.loginIndicators.loading;
  }

  private get createController() {
    return this.$container.get<ElementUiValidationControllerFactory>(
      iocTypes.ElementUiValidationControllerFactory
    );
  }

  private get token() {
    return this.$route.query.token as string | undefined;
  }

  private get formValidator() {
    return this.$container.get<FormValidatorFactory>(iocTypes.FormValidatorFactory);
  }

  private get validator() {
    return this.$container.get<ValidatorBuilderFactory>(iocTypes.ValidatorBuilderFactory);
  }

  private get loginStore() {
    return this.$container.get<LoginStore>(iocTypes.LoginStore);
  }

  private get appDataService() {
    return this.$container.get<AppDataService>(iocTypes.AppDataService);
  }

  private get registrationAvailable() {
    return !this.appDataService.appData.preferences.registration_by_token;
  }
  private submitFrom() {
    this.validationController.validateForm();

    if (!this.validationController.allValid) {
      return;
    }

    if (this.recaptchaData.enabled && this.recaptchaData.key) {
      this.$refs.recaptcha.execute();
    } else {
      this.login(null);
    }
  }

  private async login(reCaptchaKey: string | null) {
    const { login: email, password } = this.form;
    const token = this.token || '';
    await this.loginStore.login({
      email,
      password,
      'g-recaptcha-response': reCaptchaKey || null,
      token,
    });

    if (this.$refs.recaptcha !== undefined) {
      this.$refs.recaptcha.reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control {
    display: block;
  }
}
</style>
