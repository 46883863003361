<template>
  <div class="app-locale-switch">
    <img class="app-locale-switch__flag" :src="localeIcon" />

    <ElDropdown @command="handleLocaleSelect">
      <div class="app-locale-switch__locale-name">
        {{ getLocaleName(currentLocale) }}
        <i class="el-icon-arrow-down el-icon--right app-locale-switch__arrow"></i>
      </div>

      <template #dropdown>
        <ElDropdownMenu slot="dropdown">
          <ElDropdownItem
            v-for="localeData in localesToSelect"
            :key="localeData.value"
            :command="localeData.value"
            >{{ localeData.label }}</ElDropdownItem
          >
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { Locale } from '@/shared/localization/enumerations/Locale';
import enFlag from '@/assets/images/flags/en.svg';
import uaFlag from '@/assets/images/flags/ua.svg';
import ruFlag from '@/assets/images/flags/ru.svg';

@Component
export default class AppLocaleSwitch extends Vue {
  get currentLocale() {
    return this.localization.locale;
  }

  get localesToSelect() {
    return this.localization.localesList
      .filter(l => l !== this.currentLocale)
      .map(l => ({ value: l, label: this.getLocaleName(l) }));
  }

  get localeIcon(): string {
    return {
      [Locale.English]: enFlag,
      [Locale.Ukrainian]: uaFlag,
      [Locale.Russian]: ruFlag,
    }[this.localization.locale];
  }

  get localization() {
    return this.$container.get<LocalizationService>(iocTypes.LocalizationService);
  }

  getLocaleName(locale: Locale) {
    return this.$t(
      {
        [Locale.English]: 'locale__en',
        [Locale.Ukrainian]: 'locale__uk',
        [Locale.Russian]: 'locale__ru',
      }[locale]
    );
  }

  handleLocaleSelect(locale: Locale) {
    this.localization.setLocale(locale);
  }
}
</script>

<style lang="scss" scoped>
.app-locale-switch {
  display: flex;
  align-items: center;

  &__flag {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 12px;
    display: block;
  }

  &__arrow {
    color: $--color-primary;
    position: relative;
    top: 1px;
    font-size: 14px;
  }
}
</style>
