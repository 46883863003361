<template>
  <div class="app-layout-skeleton">
    <section class="app-layout-skeleton__section app-layout-skeleton__banner">
      <slot name="banner" />
    </section>

    <section class="app-layout-skeleton__section app-layout-skeleton__content">
      <header class="app-layout-skeleton__header">
        <slot name="header" />
      </header>

      <div class="app-layout-skeleton__main">
        <slot />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import { iocTypes } from '@/shared/ioc/types';
import defaultAppLogo from '@/assets/images/app-logo.png';

@Component
export default class AppLayoutSkeleton extends Vue {
  get logo() {
    //FIXME: fix
    return defaultAppLogo || this.appDataService.appData.urls.org_logo;
  }

  get appDataService() {
    return this.$container.get<AppDataService>(iocTypes.AppDataService);
  }
}
</script>

<style lang="scss" scoped>
.app-layout-skeleton {
  min-height: 100%;
  display: flex;
  align-items: stretch;
  background: $--theme-color-background;

  &__section {
    box-sizing: border-box;
    width: 50%;
  }

  &__banner {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $--theme-color-dark;
    padding: 68px;
  }

  &__logo {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &__content {
    padding: 42px 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 492px;
  }

  &__main {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
}
</style>
