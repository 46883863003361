<template>
  <AppLayoutSkeleton class="app-layout">
    <template #banner>
      <img class="app-layout__logo" :src="logo" />
    </template>

    <template #header>
      <AppLocaleSwitch />
    </template>

    <RouterView />
  </AppLayoutSkeleton>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import { iocTypes } from '@/shared/ioc/types';
import defaultAppLogo from '@/assets/images/app-logo.png';
import AppLayoutSkeleton from './AppLayoutSkeleton.vue';
import AppLocaleSwitch from './AppLocaleSwitch.vue';

@Component({
  components: {
    AppLayoutSkeleton,
    AppLocaleSwitch,
  },
})
export default class AppLayout extends Vue {
  get logo() {
    //FIXME: fix
    return defaultAppLogo || this.appDataService.appData.urls.org_logo;
  }

  get appDataService() {
    return this.$container.get<AppDataService>(iocTypes.AppDataService);
  }
}
</script>

<style lang="scss" scoped>
.app-layout {
  min-height: 100%;
  display: flex;

  &__section {
    box-sizing: border-box;
    height: 100%;
    width: 50%;
  }

  &__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: blue;
    padding: 68px;
  }

  &__logo {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
