import type { interfaces } from 'inversify';
import { HttpClientFactory } from '@corefy/http/services/HttpClient';
import { iocTypes } from '@/shared/ioc/types';

export const createApiHttpClient = (ctx: interfaces.Context) => {
  const clientFactory = ctx.container.get<HttpClientFactory>(iocTypes.HttpClientFactory);
  const baseURL = window.location.protocol + '//' + window.location.host;

  return clientFactory({
    baseURL,
  });
};
