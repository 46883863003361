import { interfaces } from 'inversify';
import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators';
import { IndicatorsService } from '@/shared/action-indicators/services/IndicatorsService';
import { LoginStore } from '@/shared/store/modules/LoginStore';
import { iocTypes } from '@/shared/ioc/types';
import { AppStore } from '@/shared/store/interfaces/AppStore';
import { EntranceHttpClient } from '@/shared/api/services/EntranceHttpClient';
import type Router from 'vue-router';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { StoreError } from '@/shared/error-handling/utils/errors/StoreError';

export const createLoginStore = (ctx: interfaces.Context): LoginStore => {
  // Dependencies
  const store = ctx.container.get<AppStore>(iocTypes.AppStore);
  const indicatorsService = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const entranceHttp = ctx.container.get<EntranceHttpClient>(iocTypes.EntranceHttpClient);
  const router = ctx.container.get<Router>(iocTypes.Router);
  const localization = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);

  // Consts
  const indicatorKeys = {
    login: Symbol.for('login'),
    twoStepConfirm: Symbol.for('twoStepConfirm'),
  };

  // Store module
  @Module({ dynamic: true, store, name: 'LoginStore', namespaced: true })
  class LoginStoreModule extends VuexModule implements LoginStore {
    get loginIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.login);
    }

    get twoStepConfirmIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.twoStepConfirm);
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.login)
    async login(payload: {
      email: string;
      password: string;
      'g-recaptcha-response': string | null;
      token: string;
    }) {
      try {
        const { data } = await entranceHttp.login(payload);

        if (data.need2FA) {
          await router.push({ name: '2fa' });
          return;
        }

        if (!data.success) {
          throw new StoreError(localization.t('error__invalid_credentials'));
        }

        location.href = data.redirectTo || payload.token ? `/?token=${payload.token}` : '/';
      } catch (e: any) {
        const translationKey =
          e.response.data.message === 'Email not confirmed'
            ? 'error__account_is_inactive'
            : 'error__invalid_credentials';

        throw new StoreError(localization.t(translationKey));
      }
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.twoStepConfirm)
    async twoStepConfirm(payload: { code: string }) {
      const { data, status } = await entranceHttp.confirmTFA(payload);

      if (status !== 200 || (status === 200 && data.error === 'code_invalid')) {
        throw new Error(localization.t('error__tfa_code_validation_fail'));
      }

      location.href = data.redirectTo || '/';
    }
  }

  return getModule(LoginStoreModule);
};
