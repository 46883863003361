import { inject, injectable } from 'inversify';
import { iocTypes } from '@/shared/ioc/types';
import { HttpClient } from '@corefy/http/services/HttpClient';
import { ChangePasswordResponsePayload } from '../EntranceHttpClient';
import {
  EntranceHttpClient,
  LoginRequestPayload,
  LoginResponsePayload,
  TFAConfirmRequestPayload,
  TFAConfirmResponsePayload,
  RegistrationRequestPayload,
  ConfirmRegistrationRequestPayload,
  ConfirmRegistrationResponsePayload,
  ResetPasswordRequestPayload,
  ChangePasswordRequestPayload,
  ResetPasswordResponsePayload,
} from '@/shared/api/services/EntranceHttpClient';

@injectable()
export class DefaultEntranceHttpClient implements EntranceHttpClient {
  constructor(@inject(iocTypes.ApiHttpClient) private readonly _apiClient: HttpClient) {}

  login(data: LoginRequestPayload) {
    return this._apiClient.post<LoginResponsePayload>('/login_check', data);
  }

  confirmTFA(data: TFAConfirmRequestPayload) {
    const form = new FormData();
    form.append('auth_code', data.code);

    return this._apiClient.post<TFAConfirmResponsePayload>('/2fa_check', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  register(data: RegistrationRequestPayload) {
    return this._apiClient.post<any>('/registration', data);
  }

  confirmRegistration(data: ConfirmRegistrationRequestPayload) {
    return this._apiClient.post<ConfirmRegistrationResponsePayload>(
      '/registration/confirmation',
      data
    );
  }

  resetPassword(data: ResetPasswordRequestPayload) {
    return this._apiClient.post<ResetPasswordResponsePayload>('/resetting', data);
  }

  changePassword(data: ChangePasswordRequestPayload) {
    return this._apiClient.post<ChangePasswordResponsePayload>('/resetting/password', data);
  }
}
