import { interfaces } from 'inversify';
import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators';
import { IndicatorsService } from '@/shared/action-indicators/services/IndicatorsService';
import {
  RegistrationStore,
  ConfirmRegistrationPayload,
} from '@/shared/store/modules/RegistrationStore';
import { iocTypes } from '@/shared/ioc/types';
import { AppStore } from '@/shared/store/interfaces/AppStore';
import {
  EntranceHttpClient,
  RegistrationRequestPayload,
} from '@/shared/api/services/EntranceHttpClient';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { StoreError } from '@/shared/error-handling/utils/errors/StoreError';
import { WarningError } from '@/shared/error-handling/utils/errors/WarningError';

export const createRegistrationStore = (ctx: interfaces.Context): RegistrationStore => {
  // Dependencies
  const store = ctx.container.get<AppStore>(iocTypes.AppStore);
  const indicatorsService = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const entranceHttp = ctx.container.get<EntranceHttpClient>(iocTypes.EntranceHttpClient);
  const localization = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);

  // Consts
  const indicatorKeys = {
    confirmRegistration: Symbol.for('confirmRegistration'),
    register: Symbol.for('register'),
  };

  // Store module
  @Module({ dynamic: true, store, name: 'RegistrationStore', namespaced: true })
  class RegistrationStoreModule extends VuexModule implements RegistrationStore {
    get registrationIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.register);
    }

    get confirmRegistrationIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.confirmRegistration);
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.register)
    async register(payload: RegistrationRequestPayload) {
      try {
        await entranceHttp.register(payload);
      } catch (e: any) {
        const t = (key: string) => localization.t(key);

        const errors = e.response.data?.errors || {};
        const status = e.response?.status;

        if (status === 500) {
          throw new StoreError(t('error__fail_backend'));
        }

        if (errors.email && errors.email.match(/^User.*exists$/)) {
          throw new StoreError(t('error__user_already_exists'));
        }

        if (errors.token && errors.token === 'Token does not exist or expired') {
          throw new StoreError(t('error__token_expired'));
        }

        if (errors?.[0].title === 'This email not fit for this token') {
          throw new StoreError(t('error__token_not_fit'));
        }

        throw new StoreError(t('error__unknown_error'));
      }
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.confirmRegistration)
    async confirmRegistration(payload: ConfirmRegistrationPayload) {
      try {
        await entranceHttp.confirmRegistration(payload);
      } catch (e: any) {
        const t = (key: string) => localization.t(key);
        const status = e.response?.status;

        if (status >= 500) {
          throw new WarningError(t('error__fail_backend'));
        }

        if (status === 422) {
          throw new WarningError(t('error__token_expired'));
        }

        throw new WarningError(t('error__unknown_error'));
      }
    }
  }

  return getModule(RegistrationStoreModule);
};
