import { ErrorReporter, ErrorContext } from '@corefy/error/services/ErrorReporter';
import * as SentryTypes from '@sentry/vue';

export type Sentry = typeof SentryTypes;

/**
 * Error reporter implementation for sentry
 */
export class VueSentryErrorReporterImpl implements ErrorReporter {
  constructor(private readonly _sentry: Sentry) {}

  async report(error: Error, { context }: { context?: ErrorContext } = {}) {
    this._sentry.captureException(error, context && { tags: context });
  }
}

//FIXME: add default tags
export const vueSentryErrorReporterFactory = (sentry: Sentry) =>
  new VueSentryErrorReporterImpl(sentry);
