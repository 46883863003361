import { iocTypes } from '@/shared/ioc/types';
import { inject, injectable } from 'inversify';
import { IndicatorsService } from '@/shared/action-indicators/services/IndicatorsService';
import {
  IndicatorsStore,
  IndicatorsBindingKey,
} from '@/shared/action-indicators/store/IndicatorsStore';
import { ErrorHandlingService } from '@/shared/error-handling/services/ErrorHandlingService';
import { SilentError } from '@/shared/error-handling/utils/errors/SilentError';

@injectable()
export class IndicatorsServiceImpl implements IndicatorsService {
  constructor(
    @inject(iocTypes.IndicatorsStore)
    private readonly _indicatorsStore: IndicatorsStore,
    @inject(iocTypes.ErrorHandlingService)
    private readonly _errorHandlingService: ErrorHandlingService
  ) {}

  getIndicators(key: IndicatorsBindingKey) {
    return this._indicatorsStore.getIndicators(key);
  }

  bindIndicators(
    bindingKey: IndicatorsBindingKey,
    options?: { silentError?: boolean }
  ): (target: any, key: string, descriptor: PropertyDescriptor) => TypedPropertyDescriptor<any> {
    return (target: any, key: string, descriptor: PropertyDescriptor) => {
      const { silentError = false } = options || {};
      const { value, writable, configurable, enumerable } = descriptor;
      this._indicatorsStore.registerIndicators(bindingKey);
      const self = this;

      return {
        writable,
        configurable,
        enumerable,
        async value(...args: any[]) {
          self._indicatorsStore.setLoading({ key: bindingKey, value: true });

          try {
            await value.call(this, ...args);
            self._indicatorsStore.setError({ key: bindingKey });
            self._indicatorsStore.setSuccess({ key: bindingKey, value: true });
          } catch (e) {
            let error: any = e;

            if (silentError) {
              error = new SilentError(e as any);
            }
            self._indicatorsStore.setError({ key: bindingKey, error });
            self._indicatorsStore.setSuccess({ key: bindingKey, value: false });
            self._errorHandlingService.handleError(error);
          } finally {
            self._indicatorsStore.setLoading({ key: bindingKey, value: false });
          }
        },
      };
    };
  }
}
