<template>
  <div class="change-password">
    <h1 class="form-title">
      {{ $t('change_password__title') }}
    </h1>

    <template v-if="changesSuccess">
      <p class="form-description">{{ $t('change_password__success') }}</p>

      <div class="form-description">
        {{ $t('common__go_to') }}

        <RouterLink :to="{ name: 'login' }" class="el-link">
          {{ $t('common__go_to_login') }}
        </RouterLink>
      </div>
    </template>

    <AppForm
      v-else
      :controller="validationController"
      #default="{ formValid }"
      @submit.native.prevent="submitFrom"
    >
      <AppFormItem
        :controller="validationController"
        prop="password"
        :label="$t('common__password')"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.password"
          v-debounce:500ms="validate"
          :placeholder="$t('common__password_placeholder')"
          :readonly="loading"
          autocomplete="on"
          type="password"
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
        >
          <template #prefix>
            <div class="form-icon">
              <FontAwesome icon="lock-alt" />
            </div>
          </template>
        </ElInput>
      </AppFormItem>

      <AppFormItem
        :controller="validationController"
        prop="confirmPassword"
        :label="$t('change_password__confirm_password')"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.confirmPassword"
          v-debounce:500ms="validate"
          :placeholder="$t('common__password_placeholder')"
          :readonly="loading"
          autocomplete="on"
          type="password"
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
        >
          <template #prefix>
            <div class="form-icon">
              <FontAwesome icon="lock-alt" />
            </div>
          </template>
        </ElInput>
      </AppFormItem>

      <AppFormItem class="form-controls">
        <div class="change-password-form__controls">
          <ElButton
            type="primary"
            native-type="submit"
            :disabled="!formValid"
            :loading="loading"
            class="change-password-form__control"
          >
            {{ $t('change_password__reset') }}
          </ElButton>

          <RouterLink :to="{ name: 'login' }" class="el-link change-password-form__control">
            {{ $t('common__login_page') }}
          </RouterLink>
        </div>
      </AppFormItem>
    </AppForm>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { FormValidatorFactory } from '@corefy/vue-validation/services/FormValidator';
import { FormValidationController } from '@corefy/vue-validation/services/FormValidationController';
import { ValidatorBuilderFactory } from '@/shared/validation/services/ValidatorBuilder';
import { ElementUiValidationControllerFactory } from '@/shared/element-ui/services/ElementUiValidationController';
import { PasswordRecoveryStore } from '@/shared/store/modules/PasswordRecoveryStore';

interface Form {
  password: string;
  confirmPassword: string;
}

@Component
export default class ChangePasswordPage extends Vue {
  private form: Form = {
    password: '',
    confirmPassword: '',
  };

  private validationController: FormValidationController<Form> = this.createController<Form>({
    form: this.form,
    validator: this.formValidator({
      password: this.validator().setRequired(true).getResult(),
      confirmPassword: this.validator()
        .setRequired(true)
        .useValidator({
          validate: (value: string) => ({
            valid: this.form.password === value,
            errorMessage: this.$t('validation__password_not_match') as string,
          }),
        })
        .getResult(),
    }),
  });

  private get loading() {
    return this.recoveryStore.changePasswordIndicators.loading;
  }

  private get createController() {
    return this.$container.get<ElementUiValidationControllerFactory>(
      iocTypes.ElementUiValidationControllerFactory
    );
  }

  private get formValidator() {
    return this.$container.get<FormValidatorFactory>(iocTypes.FormValidatorFactory);
  }

  private get validator() {
    return this.$container.get<ValidatorBuilderFactory>(iocTypes.ValidatorBuilderFactory);
  }

  private get recoveryStore() {
    return this.$container.get<PasswordRecoveryStore>(iocTypes.PasswordRecoveryStore);
  }

  private get changesSuccess() {
    return this.$route.query.success;
  }

  private get token() {
    return this.$route.params.token;
  }

  private submitFrom() {
    this.validationController.validateForm();

    if (!this.validationController.allValid) {
      return;
    }

    this.changePassword();
  }

  private async changePassword() {
    const { password: newPassword, confirmPassword } = this.form;
    const { token } = this;

    await this.recoveryStore.changePassword({
      token,
      password: newPassword,
      confirm_password: confirmPassword,
    });

    if (this.recoveryStore.changePasswordIndicators.success) {
      this.$router.replace({ name: 'change-password', query: { success: 'true' } });
    }
  }
}
</script>

<style lang="scss" scoped>
.change-password-form {
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control {
    display: block;
  }
}
</style>
