import Vue from 'vue';
import FieldValidation from '@corefy/vue-validation/components/FieldValidation';
import FormValidation from '@corefy/vue-validation/components/FormValidation';
import AppForm from '@/shared/element-ui/components/AppForm.vue';
import AppFormItem from '@/shared/element-ui/components/AppFormItem.vue';

const componentsMap = {
  FieldValidation,
  FormValidation,
  AppForm,
  AppFormItem,
};

Object.entries(componentsMap).forEach(([key, value]) => Vue.component(key, value));
