<template>
  <div class="registration-page">
    <h1 class="form-title">
      {{ $t('registration__title') }}
    </h1>

    <template v-if="registrationSuccess">
      <p class="form-description">
        {{ $t(isTokenRegistration ? 'registration__success_by_token' : 'registration__success') }}
      </p>

      <div class="form-description">
        {{ $t('common__go_to') }}

        <RouterLink :to="{ name: 'login' }" class="el-link">
          {{ $t('common__go_to_login') }}
        </RouterLink>
      </div>
    </template>

    <AppForm
      v-else
      :controller="validationController"
      #default="{ formValid }"
      @submit.native.prevent="submitFrom"
    >
      <AppFormItem
        :controller="validationController"
        prop="email"
        :label="$t('common__email')"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.email"
          v-debounce:500ms="validate"
          :placeholder="$t('common__email_placeholder')"
          :readonly="loading || !!token"
          autocomplete="on"
          type="email"
          autofocus
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
        >
          <template #prefix>
            <div class="form-icon">
              <FontAwesome icon="envelope" />
            </div>
          </template>
        </ElInput>
      </AppFormItem>

      <AppFormItem
        :controller="validationController"
        prop="password"
        :label="$t('common__password')"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.password"
          v-debounce:500ms="validate"
          :placeholder="$t('common__password_placeholder')"
          :readonly="loading"
          autocomplete="on"
          type="password"
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
        >
          <template #prefix>
            <div class="form-icon">
              <FontAwesome icon="lock-alt" />
            </div>
          </template>
        </ElInput>
      </AppFormItem>

      <AppFormItem
        :controller="validationController"
        prop="confirmPassword"
        :label="$t('change_password__confirm_password')"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.confirmPassword"
          v-debounce:500ms="validate"
          :placeholder="$t('common__password_placeholder')"
          :readonly="loading"
          autocomplete="on"
          type="password"
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
        >
          <template #prefix>
            <div class="form-icon">
              <FontAwesome icon="lock-alt" />
            </div>
          </template>
        </ElInput>
      </AppFormItem>

      <AppFormItem class="form-controls">
        <div class="registration-page__controls">
          <ElButton type="primary" native-type="submit" :disabled="!formValid" :loading="loading">
            {{ $t('common__register') }}
          </ElButton>

          <VueRecaptcha
            v-if="recaptchaData.enabled && recaptchaData.key"
            ref="recaptcha"
            size="invisible"
            loadRecaptchaScript
            @verify="register"
            @expired="submitFrom"
            :sitekey="recaptchaData.key"
          ></VueRecaptcha>

          <RouterLink :to="{ name: 'login' }" class="el-link registration-page__control">
            {{ $t('common__login_page') }}
          </RouterLink>
        </div>
      </AppFormItem>
    </AppForm>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { FormValidatorFactory } from '@corefy/vue-validation/services/FormValidator';
import { FormValidationController } from '@corefy/vue-validation/services/FormValidationController';
import { ValidatorBuilderFactory } from '@/shared/validation/services/ValidatorBuilder';
import { ElementUiValidationControllerFactory } from '@/shared/element-ui/services/ElementUiValidationController';
import { RegistrationStore } from '@/shared/store/modules/RegistrationStore';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import VueRecaptcha from 'vue-recaptcha';
import RecaptchaType from 'vue-recaptcha/types';

interface Form {
  email: string;
  password: string;
  confirmPassword: string;
}

@Component({ components: { VueRecaptcha } })
export default class RegistrationPage extends Vue {
  $refs!: {
    recaptcha: RecaptchaType;
  };

  async created() {
    if (this.tokenOnlyRegistration && !this.token) {
      this.$router.replace({ name: 'login' });
    }

    this.form.email = this.email || '';
  }

  private form: Form = {
    email: '',
    password: '',
    confirmPassword: '',
  };
  private recaptchaData = this.appDataService.appData.recaptcha;

  private validationController: FormValidationController<Form> = this.createController<Form>({
    form: this.form,
    validator: this.formValidator({
      email: this.token
        ? undefined
        : this.validator().setRequired(true).useCustom.emailValidator().getResult(),
      password: this.validator().setRequired(true).getResult(),
      confirmPassword: this.validator()
        .setRequired(true)
        .useValidator({
          validate: (value: string) => ({
            valid: this.form.password === value,
            errorMessage: this.$t('validation__password_not_match') as string,
          }),
        })
        .getResult(),
    }),
  });

  private get loading() {
    return this.registrationStore.registrationIndicators.loading;
  }

  private get createController() {
    return this.$container.get<ElementUiValidationControllerFactory>(
      iocTypes.ElementUiValidationControllerFactory
    );
  }

  private get formValidator() {
    return this.$container.get<FormValidatorFactory>(iocTypes.FormValidatorFactory);
  }

  private get validator() {
    return this.$container.get<ValidatorBuilderFactory>(iocTypes.ValidatorBuilderFactory);
  }

  private get registrationStore() {
    return this.$container.get<RegistrationStore>(iocTypes.RegistrationStore);
  }

  private get appDataService() {
    return this.$container.get<AppDataService>(iocTypes.AppDataService);
  }

  private get tokenOnlyRegistration() {
    return this.appDataService.appData.preferences.registration_by_token;
  }

  private get registrationSuccess() {
    return this.$route.query.success;
  }

  private get isTokenRegistration() {
    return this.$route.query.byToken;
  }

  private get token() {
    return this.$route.query.token as string | undefined;
  }

  private get email() {
    return this.$route.query.email as string | undefined;
  }

  private submitFrom() {
    this.validationController.validateForm();

    if (!this.validationController.allValid) {
      return;
    }

    if (this.recaptchaData.enabled && this.recaptchaData.key) {
      this.$refs.recaptcha.execute();
    } else {
      this.register(null);
    }
  }

  private async register(reCaptchaKey: string | null) {
    const { email, password, confirmPassword } = this.form;

    await this.registrationStore.register({
      token: this.token || null,
      email,
      password,
      confirm_password: confirmPassword,
      'g-recaptcha-response': reCaptchaKey || null,
    });

    if (this.registrationStore.registrationIndicators.success) {
      this.$router.replace({
        name: 'registration',
        query: { success: 'true', byToken: this.token },
      });
    }

    if (this.$refs.recaptcha !== undefined) {
      this.$refs.recaptcha.reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.registration-page {
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control {
    display: block;
  }
}
</style>
