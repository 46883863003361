// If for some reason you change the standard, be sure to update the document.
/**
 * Codes of locales supported by at least one HPP.
 * Used standard is ISO 639-1.
 */
const Locale = {
  English: 'en' as const,
  Russian: 'ru' as const,
  Ukrainian: 'uk' as const,
};

type Locale = typeof Locale[keyof typeof Locale];

export { Locale };
