<template>
  <AppLayout id="app">
    <RouterView />
  </AppLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import AppLayout from '@/shared/layouts/components/AppLayout.vue';

@Component({
  components: {
    AppLayout,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100%;
  min-width: 992px;
}
</style>
