import {
  NotificationService,
  NotificationOptions,
} from '@/shared/notifications/services/NotificationService';
import { injectable } from 'inversify';
import { Message, Notification } from 'element-ui';
import { TranslateResult } from 'vue-i18n';

@injectable()
export class DefaultNotificationService implements NotificationService {
  error(message: string | number | TranslateResult, options?: NotificationOptions): void {
    const { showClose, duration } = this._prepareOptions(options);

    Message.error({
      showClose,
      duration,
      message: `${message}`,
    });
  }

  info(message: string | number | TranslateResult, options?: NotificationOptions): void {
    const { showClose, duration } = this._prepareOptions(options);

    Message.info({
      showClose,
      duration,
      message: `${message}`,
    });
  }

  success(message: string | number | TranslateResult, options?: NotificationOptions): void {
    const { showClose, duration } = this._prepareOptions(options);

    Message.success({
      showClose,
      duration,
      message: `${message}`,
    });
  }

  customNotification(
    message: string | number | TranslateResult,
    options?: NotificationOptions
  ): void {
    const { showClose, duration, type, title, customClass } = this._prepareOptions(options);
    Notification({
      message: message,
      title,
      type,
      duration,
      showClose,
      customClass,
    });
  }

  closeAll(): void {
    Message.closeAll();
  }

  private _prepareOptions(options?: NotificationOptions) {
    const {
      closable: showClose = true,
      duration = 3000,
      type = 'info',
      title = '',
      customClass = '',
    } = options || {};

    return { showClose, duration, type, title, customClass };
  }
}
