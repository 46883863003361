<template>
  <div class="confirm-registration-page">
    <h1 class="form-title">
      {{ $t('confirm_registration__title') }}
    </h1>

    <p v-if="error" class="form-description confirm-registration-page__error">
      {{ error }}
    </p>

    <p v-else class="form-description">
      {{ $t('confirm_registration__success') }}
    </p>

    <div class="form-description">
      {{ $t('common__go_to') }}

      <RouterLink :to="{ name: 'login' }" class="el-link">
        {{ $t('common__go_to_login') }}
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { RegistrationStore } from '@/shared/store/modules/RegistrationStore';

@Component
export default class RegistrationPage extends Vue {
  created() {
    this.registrationStore.confirmRegistration({ token: this.token });
  }

  private get loading() {
    return this.registrationStore.confirmRegistrationIndicators.loading;
  }

  private get error() {
    return this.registrationStore.confirmRegistrationIndicators.error?.message;
  }

  private get registrationStore() {
    return this.$container.get<RegistrationStore>(iocTypes.RegistrationStore);
  }

  private get token() {
    return this.$route.params.token as string;
  }
}
</script>

<style lang="scss" scoped>
.confirm-registration {
  &__error {
    color: $--color-danger;
  }
}
</style>
