import { DefaultFormValidationController } from '@corefy/vue-validation/services/implementations/DefaultFormValidationController';
import {
  ElementUiValidationController,
  ElementUiValidationControllerFactory,
  ElementUiValidationControllerConfig,
  ValidationRule,
} from '@/shared/element-ui/services/ElementUiValidationController';
import type { ElForm } from 'element-ui/types/form';
import { ValidationResult } from '@corefy/validation/interfaces/Validator';

const isValidationResult = (value: any): value is ValidationResult => !!value;

export class ElementUiValidationControllerImpl<T>
  extends DefaultFormValidationController<T>
  implements ElementUiValidationController<T>
{
  validateForm() {
    super.validateForm();

    if (this.uiForm) {
      // eslint-disable-next-line
      this.uiForm.validate(() => {});
    }
  }

  validateField(key: keyof T) {
    super.validateField(key);
    if (this.uiForm) {
      // eslint-disable-next-line
      this.uiForm.validateField(key as string, () => {});
    }
  }

  clearValidation() {
    super.clearValidation();

    if (this.uiForm) {
      this.uiForm.clearValidate();
    }
  }

  clearFieldValidation(field: keyof T) {
    super.clearFieldValidation(field);

    if (this.uiForm) {
      this.uiForm.clearValidate(field as string);
    }
  }

  getFormRules() {
    return Object.keys(this.validator.validatorsMap).reduce((accum, key) => {
      accum[key as keyof T] = this._createRuleStub(key as keyof T);
      return accum;
    }, {} as { [K in keyof T]: ValidationRule });
  }

  private _createRuleStub = (prop: keyof T) => {
    return {
      trigger: 'manual',
      validator: (rule: any, value: any, cb: (error?: Error) => void) => {
        const res = this.validationResult[prop];

        if (isValidationResult(res)) {
          const { valid, errorMessage } = res;

          if (valid) {
            cb();
          } else {
            cb(new Error(errorMessage));
          }
        } else {
          cb();
        }
      },
    };
  };

  setFormGetter(getter: () => ElForm) {
    this._getForm = getter;
  }

  private get uiForm() {
    return this._getForm && this._getForm();
  }

  private _getForm?: () => ElForm;
}

export const elementUiValidationControllerFactory: ElementUiValidationControllerFactory = <T>(
  config: ElementUiValidationControllerConfig<T>
) => new ElementUiValidationControllerImpl(config);
