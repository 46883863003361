<template>
  <div class="tfa-form">
    <h1 class="form-title">
      {{ $t('tfa__title') }}
    </h1>

    <AppForm
      :controller="validationController"
      #default="{ formValid }"
      @submit.native.prevent="submit"
    >
      <AppFormItem
        :controller="validationController"
        prop="code"
        :label="$t('tfa__code')"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.code"
          placeholder="######"
          :readonly="loading"
          v-debounce:500ms="validate"
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
          @input.native="e => $isReplacement(e) && validate()"
        />
      </AppFormItem>

      <AppFormItem class="form-controls">
        <div class="tfa-form__controls">
          <ElButton
            type="primary"
            native-type="submit"
            :disabled="!formValid"
            :loading="loading"
            class="tfa-form__control"
          >
            {{ $t('common__confirm') }}
          </ElButton>
        </div>
      </AppFormItem>
    </AppForm>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { FormValidatorFactory } from '@corefy/vue-validation/services/FormValidator';
import { FormValidationController } from '@corefy/vue-validation/services/FormValidationController';
import { ValidatorBuilderFactory } from '@/shared/validation/services/ValidatorBuilder';
import { ElementUiValidationControllerFactory } from '@/shared/element-ui/services/ElementUiValidationController';
import { TfaStore } from '@/shared/store/modules/TfaStore';

interface Form {
  code: string;
}

@Component
export default class Tfa extends Vue {
  private form: Form = {
    code: '',
  };

  private validationController: FormValidationController<Form> = this.createController<Form>({
    form: this.form,
    validator: this.formValidator({
      code: this.validator().setRequired(true).getResult(),
    }),
  });

  private get loading() {
    return this.tfaStore.validateCodeIndicators.loading;
  }

  private get createController() {
    return this.$container.get<ElementUiValidationControllerFactory>(
      iocTypes.ElementUiValidationControllerFactory
    );
  }

  private get formValidator() {
    return this.$container.get<FormValidatorFactory>(iocTypes.FormValidatorFactory);
  }

  private get validator() {
    return this.$container.get<ValidatorBuilderFactory>(iocTypes.ValidatorBuilderFactory);
  }

  private get tfaStore() {
    return this.$container.get<TfaStore>(iocTypes.TfaStore);
  }

  private async submit() {
    this.validationController.validateForm();

    if (this.validationController.allValid) {
      await this.tfaStore.validateCode(this.form);
    }
  }
}
</script>

<style lang="scss" scoped>
.tfa-form {
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control {
    display: block;
  }
}
</style>
