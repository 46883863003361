import isArray from 'lodash/isArray';
import { inject, injectable } from 'inversify';
import type { AxiosError } from 'axios';
import { iocTypes } from '@/shared/ioc/types';
import { objectToString } from '@corefy/common/utils/cast/objectToString';
import { ErrorReporter } from '@corefy/error/services/ErrorReporter';
import { ErrorHandlingService } from '@/shared/error-handling/services/ErrorHandlingService';
import { NotificationService } from '@/shared/notifications/services/NotificationService';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { SilentError } from '@/shared/error-handling/utils/errors/SilentError';
import { ServerError } from '@/shared/error-handling/interfaces/ServerError';
import { ValidationError } from '@/shared/error-handling/interfaces/ValidationError';
import { CommonError } from '@/shared/error-handling/interfaces/CommonError';
import { ErrorStatusDataAssets } from '@/shared/error-handling/consts/ErrorStatusDataAssets';
import { ErrorStatus } from '@/shared/error-handling/enumerations/ErrorStatus';
import { StoreError } from '@/shared/error-handling/utils/errors/StoreError';
import { WarningError } from '@/shared/error-handling//utils/errors/WarningError';

const isAxiosError = (e: Error): e is AxiosError => !!(e as AxiosError).isAxiosError;
const isSilentError = (e: Error): e is SilentError => e instanceof SilentError;
const isStoreError = (e: Error): e is StoreError => e instanceof StoreError;
const isWarningError = (e: Error): e is WarningError => e instanceof WarningError;

const joinMessages = (...messages: (string | undefined)[]) => messages.filter(el => el).join(' ');

//TODO: refactor using @corefy/error interfaces
@injectable()
export class DefaultErrorHandlingService implements ErrorHandlingService {
  @inject(iocTypes.NotificationService)
  private readonly _notification!: NotificationService;

  @inject(iocTypes.LocalizationService)
  private readonly _t!: LocalizationService;

  @inject(iocTypes.ErrorsReporter)
  private readonly _errorMonitoringService!: ErrorReporter;

  handleError(e: Error): void {
    if (isWarningError(e)) {
      this._errorMonitoringService.report(e);
      this._handleWarningError(e);
      return;
    }

    if (isSilentError(e)) {
      this._handleSilentError(e);
      return;
    }

    if (isStoreError(e)) {
      this._errorMonitoringService.report(e);
      this._handleSimpleError(e);
      return;
    }

    if (isAxiosError(e)) {
      this._errorMonitoringService.report(e);
      this._handleAxiosError(e);
      return;
    }

    this._errorMonitoringService.report(e);
    this._handleUnknownError(e);
  }

  private _handleSilentError(e: SilentError) {
    console.error(e.error.message);
  }

  private _handleSimpleError(e: Error) {
    this._notification.error(e.message);
  }

  private _handleWarningError(e: WarningError) {
    console.warn('WARN:', e);
  }

  private _handleAxiosError(e: AxiosError<ServerError>) {
    if (!e.response) {
      this._handleUnknownError(e);
      return;
    }

    if (e.response.status === 500) {
      const asset = ErrorStatusDataAssets.getFor(e.response.statusText as ErrorStatus);
      if (asset?.title && asset.title[this._t.locale]) {
        this._notification.error(asset.title[this._t.locale]);
      } else {
        this._notification.error(e.response.statusText);
      }
      return;
    }

    if (isArray(e.response!.data.errors)) {
      e.response!.data.errors.forEach(e => this._handleBackendError(e));
      return;
    }

    this._notification.error(e.message);
  }

  private _handleBackendError(e: CommonError | ValidationError) {
    const asset = Object.keys(ErrorStatusDataAssets).includes(e.status)
      ? ErrorStatusDataAssets.getFor(e.status as ErrorStatus)
      : null;

    const title = asset ? asset.title[this._t.locale] : e.status;

    const description =
      !e.title && asset
        ? asset.description[this._t.locale]
        : 'source' in e && e.source.pointer
        ? joinMessages(e.title, e.source.pointer)
        : e.title;

    const totalMessage = joinMessages(title, description);

    if (totalMessage) {
      this._notification.error(totalMessage);
    } else {
      this._showDefaultError();
      this._errorMonitoringService.report(
        new Error(`Error display: total error message is empty.\nError: ${objectToString(e)}`)
      );
    }
  }

  private _showDefaultError() {
    this._notification.error(this._t.t('error__unknown_error'));
  }

  private _handleUnknownError(e: Error) {
    console.error(e);
  }
}
