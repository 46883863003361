export const createLanguageAssertion = ({ matches }: { matches: (string | RegExp)[] }) => {
  return (localeCode: string): boolean => {
    return matches.some(m => {
      if (typeof m === 'string') {
        return localeCode.toLowerCase() === m.toLowerCase();
      }

      return new RegExp(m).test(localeCode);
    });
  };
};
