/**
 * @class SilentError for errors which is not notifiable
 */
export class SilentError extends Error {
  error: Error;
  silent = true;

  constructor(error: Error) {
    super();
    this.error = error;
  }
}
