import { LocalStorageService } from '@/shared/local-storage/services/LocalStorageService';
import { Locale } from '@/shared/localization/enumerations/Locale';
import { injectable } from 'inversify';

const keys = {
  locale: 'locale',
};
@injectable()
export class DefaultLocalStorageService implements LocalStorageService {
  get locale() {
    const locale = localStorage.getItem(keys.locale) as Locale | null;
    return locale && Object.values(Locale).includes(locale) ? locale : null;
  }

  set locale(locale: Locale | null) {
    if (locale) {
      localStorage.setItem(keys.locale, locale);
    } else {
      localStorage.removeItem(keys.locale);
    }
  }
}
