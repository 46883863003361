import Router from 'vue-router';
import { router } from '@/plugins/router';
import { i18n } from '@/plugins/i18n';
import { createVueI18nLocalizationService } from '@corefy/localization-vue-i18n/services/implementations/VueI18nLocalizationService';
import { TabEventEmitter } from '@/shared/tab-events/services/TabEventEmitter';
import { tabEventEmitter } from '@/shared/tab-events/services/implementations/tabEventEmitter';
import { Container } from 'inversify';
import { AppStore } from '@/shared/store/interfaces/AppStore';
import { createStore } from '@/shared/store/utils/createStore';
import { iocTypes } from '@/shared/ioc/types';
import { HttpClientFactory, HttpClient } from '@corefy/http/services/HttpClient';
import { createAxiosHttpClientFactory } from '@corefy/http-axios/services/implementation/AxiosHttpClient';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import { DefaultAppDataService } from '@/shared/app-data/services/implementations/DefaultAppDataService';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { IndicatorsService } from '@/shared/action-indicators/services/IndicatorsService';
import { IndicatorsServiceImpl } from '@/shared/action-indicators/services/implementations/IndicatorsServiceImpl';
import { IndicatorsStore } from '@/shared/action-indicators/store/IndicatorsStore';
import { indicatorsStoreInitializer } from '@/shared/action-indicators/store/implementations/IndicatorsStoreImpl';
import { NotificationService } from '@/shared/notifications/services/NotificationService';
import { DefaultNotificationService } from '@/shared/notifications/services/implementations/DefaultNotificationService';
import { ErrorHandlingService } from '@/shared/error-handling/services/ErrorHandlingService';
import { DefaultErrorHandlingService } from '@/shared/error-handling/services/implementations/DefaultErrorHandlingService';
import { FormValidatorFactory } from '@corefy/vue-validation/services/FormValidator';
import { createFormValidatorFactory } from '@corefy/vue-validation/services/implementations/DefaultFormValidator';
import { ElementUiValidationControllerFactory } from '@/shared/element-ui/services/ElementUiValidationController';
import { elementUiValidationControllerFactory } from '@/shared/element-ui/services/implementatins/ElementUiValidationController';
import { ValidatorBuilderFactory } from '@/shared/validation/services/ValidatorBuilder';
import { createCompositeValidatorFactory } from '@corefy/validation/services/implementations/CompositeValidator';
import { createEmptyValueValidatorFactory } from '@corefy/validation/services/implementations/EmptyValueValidator';
import { createArrayValidatorFactory } from '@corefy/validation/services/implementations/ArrayValidator';
import { createRequiredValidatorFactory } from '@corefy/validation/services/implementations/RequiredValidator';
import { createValidatorBuilderFactory } from '@corefy/validation/services/implementations/ValidationBuilder';
import { createRegexpValidatorFactory } from '@corefy/validation/services/implementations/RegexpValidator';
import { LocalStorageService } from '@/shared/local-storage/services/LocalStorageService';
import { DefaultLocalStorageService } from '@/shared/local-storage/services/implementations/DefaultLocalStorageService';
import { ErrorReporter } from '@corefy/error/services/ErrorReporter';
import { voidErrorReporterFactory } from '@corefy/error/services/implementations/VoidErrorReporter';
import { Locale } from '@/shared/localization/enumerations/Locale';
import axios from 'axios';
import { createApiHttpClient } from '@/shared/api/services/implementations/ApiHttpClient';
import { DefaultEntranceHttpClient } from '@/shared/api/services/implementations/DefaultEntranceHttpClient';
import { EntranceHttpClient } from '@/shared/api/services/EntranceHttpClient';
import { LoginStore } from '@/shared/store/modules/LoginStore';
import { createLoginStore } from '@/shared/store/modules/implementations/LoginStore';
import { PasswordRecoveryStore } from '@/shared/store/modules/PasswordRecoveryStore';
import { createPasswordRecoveryStore } from '@/shared/store/modules/implementations/PasswordRecoveryStore';
import { createEmailValidatorFactory } from '@/shared/validation/services/implementatios/EmailValidator';
import { createRegistrationStore } from '@/shared/store/modules/implementations/RegistrationStore';
import { RegistrationStore } from '@/shared/store/modules/RegistrationStore';
import { BrowserLocaleDetector } from '@/shared/localization/services/BrowserLocaleDetector';
import { createBrowserLocaleDetector } from '@/shared/localization/services/implementations/DefaultBrowserLocaleDetector';
import { isEnLanguageCode } from '@/shared/localization/utils/language-assertions/isEnLanguageCode';
import { isUkLanguageCode } from '@/shared/localization/utils/language-assertions/isUkLanguageCode';
import { isRuLanguageCode } from '@/shared/localization/utils/language-assertions/isRuLanguageCode';
import { TfaStore } from '@/shared/store/modules/TfaStore';
import { createTfaStore } from '@/shared/store/modules/implementations/TfaStore';
import { vueSentryErrorReporterFactory } from '../error-sentry/utils/implementations/VueSentryErrorReporter';
import { initSentry } from '../error-sentry/utils/initSentry';

const container = new Container();

// ===================================== Localization =================================

container
  .bind<LocalizationService>(iocTypes.LocalizationService)
  .toConstantValue(
    createVueI18nLocalizationService<Locale>({ i18n, locales: Object.values(Locale) as Locale[] })
  );

container.bind<BrowserLocaleDetector>(iocTypes.BrowserLocaleDetector).toConstantValue(
  createBrowserLocaleDetector<Locale>({
    detectors: {
      [Locale.English]: isEnLanguageCode,
      [Locale.Ukrainian]: isUkLanguageCode,
      [Locale.Russian]: isRuLanguageCode,
    },
  })
);

// ===================================== Store =================================

container.bind<AppStore>(iocTypes.AppStore).toConstantValue(createStore());

container
  .bind<IndicatorsService>(iocTypes.IndicatorsService)
  .to(IndicatorsServiceImpl)
  .inSingletonScope();

container
  .bind<IndicatorsStore>(iocTypes.IndicatorsStore)
  .toDynamicValue(indicatorsStoreInitializer)
  .inSingletonScope();

container.bind<LoginStore>(iocTypes.LoginStore).toDynamicValue(createLoginStore).inSingletonScope();

container
  .bind<PasswordRecoveryStore>(iocTypes.PasswordRecoveryStore)
  .toDynamicValue(createPasswordRecoveryStore)
  .inSingletonScope();

container
  .bind<RegistrationStore>(iocTypes.RegistrationStore)
  .toDynamicValue(createRegistrationStore)
  .inSingletonScope();

container.bind<TfaStore>(iocTypes.TfaStore).toDynamicValue(createTfaStore).inSingletonScope();

// ===================================== Global state =================================

container.bind<AppDataService>(iocTypes.AppDataService).to(DefaultAppDataService);

container.bind<LocalStorageService>(iocTypes.LocalStorageService).to(DefaultLocalStorageService);

container.bind<TabEventEmitter>(iocTypes.TabEventEmitter).toConstantValue(tabEventEmitter);

// ===================================== Routing =================================

container.bind<Router>(iocTypes.Router).toConstantValue(router);

// ===================================== Notifications =================================

container.bind<NotificationService>(iocTypes.NotificationService).to(DefaultNotificationService);

// ===================================== Errors =================================

container.bind<ErrorHandlingService>(iocTypes.ErrorHandlingService).to(DefaultErrorHandlingService);

container
  .bind<ErrorReporter>(iocTypes.ErrorsReporter)
  .toDynamicValue(ctx => {
    if (process.env.NODE_ENV === 'development' || !FRONT_SENTRY_DNS) {
      return voidErrorReporterFactory();
    }

    const appDataService = ctx.container.get<AppDataService>(iocTypes.AppDataService);
    const sentry = initSentry({
      dsn: FRONT_SENTRY_DNS,
      environment: appDataService.appData.environment,
      cell: appDataService.appData.application_cell,
    });

    return vueSentryErrorReporterFactory(sentry);
  })
  .inSingletonScope();
// ===================================== HTTP =================================

container
  .bind<HttpClientFactory>(iocTypes.HttpClientFactory)
  .toConstantValue(createAxiosHttpClientFactory({ axios }));

// ===================================== API =================================

container.bind<HttpClient>(iocTypes.ApiHttpClient).toDynamicValue(createApiHttpClient);

container.bind<EntranceHttpClient>(iocTypes.EntranceHttpClient).to(DefaultEntranceHttpClient);

// ===================================== Validation =================================

container
  .bind<ValidatorBuilderFactory>(iocTypes.ValidatorBuilderFactory)
  .toDynamicValue(({ container }) => {
    const l = container.get<LocalizationService>(iocTypes.LocalizationService);

    return createValidatorBuilderFactory({
      createCompositeValidator: createCompositeValidatorFactory(),

      createEmptyValueValidator: createEmptyValueValidatorFactory(),

      createArrayValidator: createArrayValidatorFactory({
        defaultMessages: {
          isNotArray: l.t('validation__is_not_array'),
          min: min => l.t('validation__array_lenght_less_than', { min }),
          max: max => l.t('validation__array_lenght_greater_than', { max }),
        },
      }),

      createRequiredValidator: createRequiredValidatorFactory({
        defaultMessages: {
          isRequired: () => l.t('validation__is_required'),
        },
      }),

      custom: {
        emailValidator: createEmailValidatorFactory(container),

        regexpValidator: createRegexpValidatorFactory({
          defaultMessages: {
            regexpNotMatch: () => l.t('validation__regexp__not_match'),
          },
        }),
      },
    });
  });

// ===================================== Vue validation =================================

container
  .bind<ElementUiValidationControllerFactory>(iocTypes.ElementUiValidationControllerFactory)
  .toConstantValue(elementUiValidationControllerFactory);

container
  .bind<FormValidatorFactory>(iocTypes.FormValidatorFactory)
  .toConstantValue(createFormValidatorFactory());

export const iocContainer = container;
