import { AppDataService } from '@/shared/app-data/services/AppDataService';
import { AppData } from '@/shared/app-data/interfaces/AppData';
import { injectable } from 'inversify';

@injectable()
export class DefaultAppDataService implements AppDataService {
  get appData(): AppData {
    return window.APP_DATA;
  }
}
