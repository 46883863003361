import * as Sentry from '@sentry/vue';
import Vue from 'vue';

export const initSentry = ({
  dsn,
  environment,
  cell,
}: {
  dsn: string;
  environment: string;
  cell: string;
}) => {
  Sentry.init({
    dsn,
    environment,
    Vue,
    release: FRONT_SENTRY_RELEASE,
  });

  const tags = {
    theme: 'paygames',
    cell,
    app: 'entrance',
  };

  Sentry.setTags(tags);

  //FIXME: fix
  return Sentry as any;
};
