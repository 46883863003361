import 'reflect-metadata';
import Vue from 'vue';
import App from '@/App.vue';
import { i18n } from '@/plugins/i18n';
import { router } from '@/plugins/router';
import '@/plugins/global-components';
import '@/plugins/dom-utils';
import '@/plugins/ioc';
import '@/plugins/element';
import '@/plugins/font-awesome';
import '@/plugins/vue-cookies';
import '@/plugins/vue-debounce';
import 'normalize.css';
import '@/styles/index.scss';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { iocTypes } from '@/shared/ioc/types';
import { BrowserLocaleDetector } from '@/shared/localization/services/BrowserLocaleDetector';
import { Locale } from '@/shared/localization/enumerations/Locale';
import { LocalStorageService } from '@/shared/local-storage/services/LocalStorageService';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import type { Route } from 'vue-router';

new Vue({
  created() {
    // Localization
    const localization = this.$container.get<LocalizationService>(iocTypes.LocalizationService);
    const localeDetect = this.$container.get<BrowserLocaleDetector>(iocTypes.BrowserLocaleDetector);
    const ls = this.$container.get<LocalStorageService>(iocTypes.LocalStorageService);

    localization.setLocale(ls.locale || localeDetect.getLocaleFromBrowser() || Locale.English);
    localization.localeSwitchEvent.addListener(l => (ls.locale = l));

    // Router
    const appDataService = this.$container.get<AppDataService>(iocTypes.AppDataService);

    const setWindowTitle = (r: Route) => {
      (document as any).title = r.meta?.translationKey
        ? localization.t(r.meta.translationKey)
        : appDataService.appData.appName;
    };

    setWindowTitle(this.$route);

    this.$router.beforeEach((to, from, next) => {
      setWindowTitle(to);
      next();
    });
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
