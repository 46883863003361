import { interfaces } from 'inversify';
import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators';
import { IndicatorsService } from '@/shared/action-indicators/services/IndicatorsService';
import { TfaStore } from '@/shared/store/modules/TfaStore';
import { iocTypes } from '@/shared/ioc/types';
import { AppStore } from '@/shared/store/interfaces/AppStore';
import { EntranceHttpClient } from '@/shared/api/services/EntranceHttpClient';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { StoreError } from '@/shared/error-handling/utils/errors/StoreError';

export const createTfaStore = (ctx: interfaces.Context): TfaStore => {
  // Dependencies
  const store = ctx.container.get<AppStore>(iocTypes.AppStore);
  const indicatorsService = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const entranceHttp = ctx.container.get<EntranceHttpClient>(iocTypes.EntranceHttpClient);
  const localization = ctx.container.get<LocalizationService>(iocTypes.LocalizationService);

  // Consts
  const indicatorKeys = {
    validateCode: Symbol.for('validateCode'),
  };

  // Store module
  @Module({ dynamic: true, store, name: 'TfaStore', namespaced: true })
  class TfaStoreModule extends VuexModule implements TfaStore {
    get validateCodeIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.validateCode);
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.validateCode)
    async validateCode({ code }: { code: string }) {
      const response = await entranceHttp.confirmTFA({ code });

      if (response.status >= 400) {
        if (response.data.error === 'code_invalid') {
          throw new StoreError(localization.t('error__invalid_tfa_code'));
        }

        throw new StoreError(localization.t('error__unknown_error'));
      }

      window.location.href = response.data.redirectTo || '/';
    }
  }

  return getModule(TfaStoreModule);
};
