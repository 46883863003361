<template>
  <FieldValidation
    class="app-form-item"
    v-if="validationPresent"
    v-bind="validationAttrs"
    v-slot="validationScope"
  >
    <ElFormItem v-bind="formItemAttrs" v-on="$listeners">
      <slot v-bind="validationScope" />

      <template v-slot:label>
        <slot name="label" />
      </template>
    </ElFormItem>
  </FieldValidation>

  <div v-else class="app-form-item">
    <ElFormItem v-bind="formItemAttrs" v-on="$listeners">
      <slot />

      <template v-slot:label>
        <slot name="label"> </slot>
      </template>
    </ElFormItem>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ElementUiValidationController } from '@/shared/element-ui/services/ElementUiValidationController';
import omit from 'lodash/omit';

const CONTROLLER_PROP = 'controller';
const FIELDNAME_PROP = 'prop';
const SHOW_ASTERIC_PROP = 'showAsteric';

const componentProps = [CONTROLLER_PROP, FIELDNAME_PROP, SHOW_ASTERIC_PROP];

@Component
export default class AppFormItem extends Vue {
  @Prop({ type: Object })
  protected [CONTROLLER_PROP]?: ElementUiValidationController<{
    [index: string]: unknown;
  }>;

  @Prop({ type: String })
  protected [FIELDNAME_PROP]?: string;

  @Prop({ type: Boolean, default: false })
  protected [SHOW_ASTERIC_PROP]!: boolean;

  get validationPresent() {
    return this[CONTROLLER_PROP];
  }

  get validationAttrs() {
    return {
      validationController: this[CONTROLLER_PROP],
      fieldName: this[FIELDNAME_PROP],
    };
  }

  get formItemAttrs() {
    return {
      ...omit(this.$attrs, ...componentProps, 'class'),
      prop: this[FIELDNAME_PROP],
      class: [this.$attrs.class, this[SHOW_ASTERIC_PROP] ? 'is-required' : undefined]
        .filter(s => s)
        .join(' '),
    };
  }
}
</script>
