<template>
  <div class="reset-password">
    <h1 class="form-title">
      {{ $t('reset_password__title') }}
    </h1>

    <template v-if="recoverySuccess">
      <p class="form-description reset-password-form__msg">
        {{ $t('reset_password__success', { email }) }}
      </p>

      <div class="form-description">
        {{ $t('common__go_to') }}

        <RouterLink :to="{ name: 'login' }" class="el-link">
          {{ $t('common__go_to_login') }}
        </RouterLink>
      </div>
    </template>

    <AppForm
      v-else
      :controller="validationController"
      @submit.native.prevent="submitFrom"
      #default="{ formValid }"
    >
      <AppFormItem
        :controller="validationController"
        :label="$t('common__email')"
        prop="login"
        #default="{ validate, clearValidation }"
      >
        <ElInput
          v-model="form.login"
          v-debounce:500ms="validate"
          :placeholder="$t('common__email_placeholder')"
          :readonly="loading"
          autocomplete="on"
          type="email"
          @blur="validate"
          @focus="clearValidation"
          @input="clearValidation"
          @input.native="e => $isReplacement(e) && validate()"
        >
          <template #prefix>
            <div class="form-icon">
              <FontAwesome icon="envelope" />
            </div>
          </template>
        </ElInput>
      </AppFormItem>

      <AppFormItem class="form-controls">
        <div class="reset-password-form__controls">
          <ElButton
            :disabled="!formValid"
            :loading="loading"
            type="primary"
            native-type="submit"
            class="reset-password-form__control"
          >
            {{ $t('reset_password__reset') }}
          </ElButton>

          <VueRecaptcha
            v-if="recaptchaData.enabled && recaptchaData.key"
            ref="recaptcha"
            :sitekey="recaptchaData.key"
            size="invisible"
            loadRecaptchaScript
            @verify="resetPassword"
            @expired="submitFrom"
          ></VueRecaptcha>

          <RouterLink :to="{ name: 'login' }" class="el-link reset-password-form__control">
            {{ $t('common__login_page') }}
          </RouterLink>
        </div>
      </AppFormItem>
    </AppForm>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { iocTypes } from '@/shared/ioc/types';
import { FormValidatorFactory } from '@corefy/vue-validation/services/FormValidator';
import { FormValidationController } from '@corefy/vue-validation/services/FormValidationController';
import { ValidatorBuilderFactory } from '@/shared/validation/services/ValidatorBuilder';
import { ElementUiValidationControllerFactory } from '@/shared/element-ui/services/ElementUiValidationController';
import { PasswordRecoveryStore } from '@/shared/store/modules/PasswordRecoveryStore';
import VueRecaptcha from 'vue-recaptcha';
import RecaptchaType from 'vue-recaptcha/types';
import { AppDataService } from '@/shared/app-data/services/AppDataService';
import { NotificationService } from '@/shared/notifications/services/NotificationService';

interface Form {
  login: string;
}

@Component({ components: { VueRecaptcha } })
export default class ResetPassword extends Vue {
  $refs!: {
    recaptcha: RecaptchaType;
  };

  private form: Form = {
    login: '',
  };

  private recaptchaData = this.appDataService.appData.recaptcha;

  private validationController: FormValidationController<Form> = this.createController<Form>({
    form: this.form,
    validator: this.formValidator({
      login: this.validator().setRequired(true).useCustom.emailValidator().getResult(),
    }),
  });

  private get loading() {
    return this.recoveryStore.recoverPasswordIndicators.loading;
  }

  private get appDataService() {
    return this.$container.get<AppDataService>(iocTypes.AppDataService);
  }

  private get createController() {
    return this.$container.get<ElementUiValidationControllerFactory>(
      iocTypes.ElementUiValidationControllerFactory
    );
  }

  private get formValidator() {
    return this.$container.get<FormValidatorFactory>(iocTypes.FormValidatorFactory);
  }

  private get validator() {
    return this.$container.get<ValidatorBuilderFactory>(iocTypes.ValidatorBuilderFactory);
  }

  private get recoveryStore() {
    return this.$container.get<PasswordRecoveryStore>(iocTypes.PasswordRecoveryStore);
  }
  private get notificationService() {
    return this.$container.get<NotificationService>(iocTypes.NotificationService);
  }

  private get recoverySuccess() {
    return this.$route.query.success;
  }

  private get email() {
    return this.$route.query.email;
  }

  private submitFrom() {
    this.validationController.validateForm();

    if (!this.validationController.allValid) {
      return;
    }

    if (this.recaptchaData.enabled && this.recaptchaData.key) {
      this.$refs.recaptcha.execute();
    } else {
      this.resetPassword(null);
    }
  }

  private async resetPassword(reCaptchaKey: string | null) {
    const { login: email } = this.form;
    await this.recoveryStore.recoverPassword({
      email,
      'g-recaptcha-response': reCaptchaKey || null,
    });

    if (this.recoveryStore.recoverPasswordIndicators.success) {
      this.$router.replace({ name: 'reset-password', query: { success: 'true', email: email } });
    }

    if (this.recoveryStore.recoverPasswordIndicators.error) {
      this.notificationService.error(this.$t('error__reset_password'));
    }

    if (this.$refs.recaptcha !== undefined) {
      this.$refs.recaptcha.reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password-form {
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control {
    display: block;
  }

  &__msg {
    word-break: break-word;
  }
}
</style>
