export const en = {
  common__email: 'Email',
  common__email_placeholder: 'privetmail@gmail.com',
  common__password: 'Password',
  common__password_placeholder: '••••••••',
  common__login: 'Log in',
  common__login_page: 'Log in',
  common__go_to: 'Go to',
  common__go_to_login: 'log in page',
  common__register: 'Register',
  common__confirm: 'Confirm',

  // Login
  login__title: 'Login',
  login__forgot_password: 'Forgot password?',

  // Reset password
  reset_password__title: 'Password recovery',
  reset_password__reset: 'Recover password',
  reset_password__success:
    'If user "{email}" exists - we will send an email with reset instructions. If you do not receive the message within a few minutes, please check spam or junk email folder, check email correctness or contact your administrator.',

  // Change password
  change_password__title: 'New password',
  change_password__reset: 'Confirm',
  change_password__confirm_password: 'Confirm password',
  change_password__success: 'Password changed successfully.',

  //Registration
  registration__title: 'Registration',
  registration__success: 'Email has been sent',
  registration__success_by_token: 'Thank you for registration',

  //Confirm registration
  confirm_registration__title: 'Account verification',
  confirm_registration__success:
    'Your account has been successfully activated. Go ahead to sign in to get started.',

  //Tfa
  tfa__title: '2FA',
  tfa__code: 'Two factor auth code',

  //Errors
  error__account_is_inactive: 'Account is inactive',
  error__fail_backend: 'Server error. Please try again later',
  error__invalid_credentials: 'Invalid credentials',
  error__token_expired: 'Token expired or invalid',
  error__unknown_error: 'Unkown error. Please try again later',
  error__user_already_exists: 'User already exists',
  error__token_not_fit: 'Token is not valid for this email',
  error__invalid_tfa_code: 'Invalid 2FA code',
  error__reset_password:
    'An error occurred while resetting the password. Please try again later or contact your administrator.',

  //Locales
  locale__en: 'English',
  locale__uk: 'Українська',
  locale__ru: 'Русский',

  // Validation
  validation__email__invalid_format: 'Incorrect email format',
  validation__is_required: 'Is required',
  validation__regexp__not_match: 'Incorrect value',
  validation__password_not_match: 'Password mismatch',
  validation__is_not_array: 'Incorrect value',
  validation__array_lenght_less_than: 'The number of values must be less or equal {min}',
  validation__array_lenght_greater_than: 'The number of values must be greater or equal {max}',
};
