export const iocTypes = {
  // Localization
  LocalizationService: Symbol.for('LocalizationService'),
  BrowserLocaleDetector: Symbol.for('BrowserLocaleDetector'),

  // Data grid
  DataGridFactory: Symbol.for('DataGridFactory'),

  // Store
  AppStore: Symbol.for('AppStore'),
  IndicatorsService: Symbol.for('IndicatorsService'),
  IndicatorsStore: Symbol.for('IndicatorsStore'),
  LoginStore: Symbol.for('LoginStore'),
  PasswordRecoveryStore: Symbol.for('PasswordRecoveryStore'),
  RegistrationStore: Symbol.for('RegistrationStore'),
  TfaStore: Symbol.for('TfaStore'),

  // Global state
  AccessScopeService: Symbol.for('AccessScopeService'),
  AppDataService: Symbol.for('AppDataService'),
  LocalStorageService: Symbol.for('LocalStorageService'),
  TabEventEmitter: Symbol.for('TabEventEmitter'),

  // Routing
  Router: Symbol.for('Router'),

  //Notifications
  NotificationService: Symbol.for('NotificationService'),

  // Errors
  ErrorHandlingService: Symbol.for('ErrorHandlingService'),
  ErrorsReporter: Symbol.for('ErrorsReporter'),

  // HTTP
  HttpClientFactory: Symbol.for('HttpClientFactory'),

  // API
  ApiHttpClient: Symbol.for('ApiHttpClient'),
  EntranceHttpClient: Symbol.for('EntranceHttpClient'),

  // Validation
  ValidatorBuilderFactory: Symbol.for('ValidatorBuilderFactory'),
  FormChangesService: Symbol.for('FormChangesService'),

  //Vue validation
  FormValidatorFactory: Symbol.for('FormValidatorFactory'),
  ElementUiValidationControllerFactory: Symbol.for('ElementUiValidationControllerFactory'),
};
