//FIXME: move to core
import { BrowserLocaleDetector } from '../CoreBrowserLocaleDetector';

export interface LocaleDetectorConfig<T extends string> {
  detectors: {
    [K in T]: (browserLocale: string) => boolean;
  };
}

export interface LocaleDetectorFactory {
  <T extends string>(config: LocaleDetectorConfig<T>): BrowserLocaleDetector<T>;
}

export class DefaultBrowserLocaleDetector<T extends string> implements BrowserLocaleDetector<T> {
  constructor(private readonly config: LocaleDetectorConfig<T>) {}

  /**
   * Extract locale from browser
   */
  getLocaleFromBrowser(): T | null {
    const browserLocale = this.getBrowserLocale();
    return this.getLocale(browserLocale);
  }

  /**
   * Get locale by locale code
   */
  getLocale(localeCode: string): T | null {
    const detectedLocale = Object.entries(
      this.config.detectors as { [index: string]: (locale: string) => boolean }
    )
      .filter(([, detect]) => detect(localeCode))
      .map(([locale]) => locale as T)
      .shift();

    return detectedLocale || null;
  }

  protected getBrowserLocale(): string {
    return navigator.language;
  }
}

export const createBrowserLocaleDetector: LocaleDetectorFactory = <T extends string>(
  config: LocaleDetectorConfig<T>
) => {
  return new DefaultBrowserLocaleDetector(config);
};
