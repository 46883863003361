import { IndicatorsSet } from '@/shared/action-indicators/interfaces/IndicatorsSet';
import { IndicatorsBindingKey } from '@/shared/action-indicators/store/IndicatorsStore';

export interface IndicatorsService {
  getIndicators(key: IndicatorsBindingKey): IndicatorsSet;
  bindIndicators(
    key: IndicatorsBindingKey,
    options?: {
      silentError?: boolean;
      notifyAboutActivity?: boolean;
    }
  ): (target: any, key: string, descriptor: PropertyDescriptor) => TypedPropertyDescriptor<any>;
}

export class BaseIndicatorsSet implements IndicatorsSet {
  get loading() {
    return !!this.loadingsCount;
  }

  public loadingsCount = 0;
  public success = false;
  public error: Error | null = null;
}
