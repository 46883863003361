import { Validator } from '@corefy/validation/interfaces/Validator';
import { createRegexpValidatorFactory } from '@corefy/validation/services/implementations/RegexpValidator';
import { emailRegExp } from '@/shared/regexp/Email';
import { interfaces } from 'inversify';
import { iocTypes } from '@/shared/ioc/types';
import { LocalizationService } from '@/shared/localization/services/LocalizationService';
import { EmailValidatorFactory } from '@/shared/validation/services/EmailValidatior';

export const createEmailValidatorFactory = (
  container: interfaces.Container
): EmailValidatorFactory => {
  const l = container.get<LocalizationService>(iocTypes.LocalizationService);
  const factory = createRegexpValidatorFactory({
    defaultMessages: {
      regexpNotMatch: '',
    },
  });

  return ({ message }: { message?: string } = {}): Validator =>
    factory(emailRegExp, {
      messages: {
        regexpNotMatch: message || l.t('validation__email__invalid_format'),
      },
    });
};
