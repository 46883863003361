import { AssetsProvider } from '@/shared/common/interfaces/AssetsProvider';
import { ErrorStatus } from '@/shared/error-handling/enumerations/ErrorStatus';
import { TranslationsDict } from '@/shared/localization/interfaces/TranslationsDict';

interface ErrorStatusAssets {
  title: TranslationsDict;
  description: TranslationsDict;
}

export const map: {
  [K in ErrorStatus]: ErrorStatusAssets;
} = {
  [ErrorStatus.AcceessDenied]: {
    title: {
      en: 'Access denied',
      ru: 'У вас нет прав для взаимодействия с этим',
      uk: 'Заборонено',
    },
    description: {
      en: 'Check your permissions',
      ru: 'Проверьте права доступа',
      uk: 'Перевірте права доступу',
    },
  },
  [ErrorStatus.InternalServerError]: {
    title: {
      en: 'Internal Server Error',
      ru: 'Internal Server Error',
      uk: 'Internal Server Error',
    },
    description: {
      en: '',
      ru: '',
      uk: '',
    },
  },
  [ErrorStatus.ValidationError]: {
    title: {
      en: 'Validation error',
      ru: 'Ошибка валидации',
      uk: 'Помилка валідації',
    },
    description: {
      en: 'Check your fields',
      ru: 'Проверьте правильность введеных данных',
      uk: 'Перевірте правильність введенних даних',
    },
  },
};

export const ErrorStatusDataAssets: AssetsProvider<ErrorStatus, ErrorStatusAssets> = {
  getAll() {
    return Object.keys(map).map(a => ({
      value: a as ErrorStatus,
      asset: this.getFor(a as ErrorStatus),
    }));
  },

  getFor(status: ErrorStatus) {
    return map[status];
  },
};
