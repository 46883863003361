export const ru = {
  common__email: 'Email',
  common__email_placeholder: 'privetmail@gmail.com',
  common__password: 'Пароль',
  common__password_placeholder: '••••••••',
  common__login: 'Войти',
  common__go_to: 'Перейти к',
  common__login_page: 'Войти',
  common__go_to_login: 'странице входа',
  common__register: 'Зарегистрироваться',
  common__confirm: 'Подтвердить',

  // Login
  login__title: 'Войдите',
  login__forgot_password: 'Забыли пароль?',

  // Reset password
  reset_password__title: 'Восстановление пароля',
  reset_password__reset: 'Восстановить пароль',
  reset_password__success:
    'Если пользователь "{email}" существует - мы отправим письмо с инструкцией по сбросу пароля. Если вы не получили письмо в течении нескольких минут - пожалуйста, проверьте: Спам и Корзину, корректность email-адреса или свяжитесь с вашим администратором.',

  // Change password
  change_password__title: 'Новый пароль',
  change_password__reset: 'Подтвердить',
  change_password__confirm_password: 'Подтвердите пароль',
  change_password__success: 'Пароль успешно изменен.',

  //Registrations
  registration__title: 'Регистрация',
  registration__success: 'Письмо отправлено на почту',
  registration__success_by_token: 'Спасибо за регистрацию',

  //Confirm registration
  confirm_registration__title: 'Подтверждение аккаунта',
  confirm_registration__success:
    'Ваш аккаунт был успешно активирован. Войдите, чтобы начать работу.',

  // Tfa
  tfa__title: '2FA',
  tfa__code: 'Код двухфакторной аутентификации',

  //Errors
  error__account_is_inactive: 'Аккаунт неактивен',
  error__fail_backend: 'Ошибка сервера. Пожалуйста, повторите попытку позже',
  error__invalid_credentials: 'Неверные учетные данные',
  error__token_expired: 'Срок действия токена истек или недействителен',
  error__unknown_error: 'Неизвестная ошибка. Пожалуйста, повторите попытку позже',
  error__user_already_exists: 'Пользователь уже существует',
  error__token_not_fit: 'Токен недействителен для этого письма',
  error__invalid_tfa_code: 'Неверный код 2FA',
  error__reset_password:
    'Возникла ошибка при сбросе пароля. Попробуйте еще раз позже или обратитесь к вашему администратору.',

  //Locales
  locale__en: 'English',
  locale__uk: 'Українська',
  locale__ru: 'Русский',

  // Validation
  validation__email__invalid_format: 'Некорректный формат почты',
  validation__is_required: 'Обязательное поле',
  validation__regexp__not_match: 'Некорректное значение',
  validation__password_not_match: 'Пароли не совпадают',
  validation__is_not_array: 'Некорректное значение',
  validation__array_lenght_less_than: 'Количество значений должно быть меньше чем {min}',
  validation__array_lenght_greater_than: 'Количество значений должно быть больше чем {max}',
};
