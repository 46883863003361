export const uk = {
  common__email: 'Email',
  common__email_placeholder: 'privetmail@gmail.com',
  common__password: 'Пароль',
  common__password_placeholder: '••••••••',
  common__login: 'Увійти',
  common__login_page: 'Увійти',
  common__go_to: 'Перейти до',
  common__go_to_login: 'сторінки входу',
  common__register: 'Зареєструватися',
  common__confirm: 'Підтвердити',

  // Login
  login__title: 'Увійдіть',
  login__forgot_password: 'Забули пароль?',

  // Reset password
  reset_password__title: 'Відновлення паролю',
  reset_password__reset: 'Відновити пароль',
  reset_password__success:
    'Якщо користувач "{email}" існує - ми надішлемо листа з інструкцією зі скидання пароля. Якщо ви не отримали лист протягом декількох хвилин - будь ласка, перевірте: Спам та Корзину, коректність email-адреси або зв\'яжіться з вашим адміністратором.',

  // Change password
  change_password__title: 'Новий пароль',
  change_password__reset: 'Підтвердити',
  change_password__confirm_password: 'Підтвердіть пароль',
  change_password__success: 'Пароль успішно змінено.',

  //Registrations
  registration__title: 'Реєстрація',
  registration__success: 'Лист був відправлений на пошту',
  registration__success_by_token: 'Дякуємо за реєстрацію',

  //Confirm registration
  confirm_registration__title: 'Підтвердження аккаунту',
  confirm_registration__success:
    'Ваш аккаунт був успішно активований. Увійдіть, щоб розпочати роботу.',

  //Errors
  error__account_is_inactive: 'Аккаунт неактивний',
  error__fail_backend: 'Помилка сервера. Будь ласка, спробуйте ще раз пізніше ',
  error__invalid_credentials: 'Невірні облікові дані',
  error__token_expired: 'Термін дії токена Текст недійсний',
  error__unknown_error: 'Невідома помилка. Будь ласка, спробуйте ще раз пізніше ',
  error__user_already_exists: 'Користувач вже існує',
  error__token_not_fit: 'Токен недійсний для цього листа',
  error__invalid_tfa_code: 'Невірний код 2FA',
  error__reset_password:
    'Виникла помилка при скиданні пароля. Спробуйте ще раз пізніше або зверніться до вашого адміністратора.',

  // Tfa
  tfa__title: '2FA',
  tfa__code: 'Код двофакторної аутентифікації',

  //Locales
  locale__en: 'English',
  locale__uk: 'Українська',
  locale__ru: 'Русский',

  // Validation
  validation__email__invalid_format: 'Некоректний формат пошти',
  validation__is_required: "Обов'язкове поле",
  validation__regexp__not_match: 'Некоректне значення',
  validation__password_not_match: 'Паролі не співпадають',
  validation__is_not_array: 'Некоректне значення',
  validation__array_lenght_less_than: 'Кількість значень має бути менше або дорівнювати {min}',
  validation__array_lenght_greater_than: 'Кількість значень має бути більше або дорівнювати {max}',
};
