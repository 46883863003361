<template>
  <FormValidation
    v-if="validationPresent"
    class="app-form"
    v-bind="validationAttrs"
    v-on="validationListeners"
    #default="validationScope"
  >
    <ElForm ref="form" v-bind="formAttrs" v-on="formListeners">
      <slot v-bind="validationScope" />

      <template v-slot:label="scope">
        <slot name="label" v-bind="scope" />
      </template>
    </ElForm>
  </FormValidation>

  <ElForm v-else class="app-form" ref="form" v-bind="formAttrs" v-on="formListeners">
    <slot />

    <template v-slot:label="scope">
      <slot name="label" v-bind="scope" />
    </template>
  </ElForm>
</template>

<script lang="ts">
import Vue from 'vue';
import omit from 'lodash/omit';
import { Component, Prop } from 'vue-property-decorator';
import { removeObjectValues } from '@corefy/common/utils/removeObjectValues';
import { ElementUiValidationController } from '@/shared/element-ui/services/ElementUiValidationController';
import type { ElForm } from 'element-ui/types/form';

const CONTROLLER_PROP = 'controller';
const validationProps = [CONTROLLER_PROP];

const CLEAR_EVENT = 'validation-clear';
const VALID_EVENT = 'valid';
const INVALID_EVENT = 'invalid';

const validationEvents = [CLEAR_EVENT, VALID_EVENT, INVALID_EVENT];

@Component
export default class AppForm extends Vue {
  $refs!: {
    form: ElForm;
  };

  @Prop({ type: Object, required: false })
  protected [CONTROLLER_PROP]?: ElementUiValidationController<{
    [index: string]: unknown;
  }>;

  mounted() {
    const controller = this[CONTROLLER_PROP];
    if (controller) {
      controller.setFormGetter(() => this.$refs.form);
    }
  }

  get validationAttrs() {
    return {
      validationController: this[CONTROLLER_PROP],
    };
  }

  get validationPresent() {
    return this[CONTROLLER_PROP];
  }

  get validationListeners() {
    return removeObjectValues(
      {
        'validation-clear': this.$listeners[CLEAR_EVENT],
        'validation-success': this.$listeners[VALID_EVENT],
        'validation-fail': this.$listeners[INVALID_EVENT],
      },
      [undefined]
    );
  }

  get formAttrs() {
    const controller = this[CONTROLLER_PROP];
    return {
      ...omit(this.$attrs, ...validationProps),
      rules: controller && controller.getFormRules(),
      model: controller && controller.form,
    };
  }

  get formListeners() {
    return omit(this.$listeners, ...validationEvents);
  }
}
</script>
