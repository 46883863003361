import { IndicatorsBindingKey } from '../IndicatorsStore';
import { interfaces } from 'inversify';
import Vue from 'vue';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AppStore } from '@/shared/store/interfaces/AppStore';
import { iocTypes } from '@/shared/ioc/types';
import { IndicatorsStore } from '@/shared/action-indicators/store/IndicatorsStore';
import { IndicatorsSet } from '@/shared/action-indicators/interfaces/IndicatorsSet';
import { BaseIndicatorsSet } from '@/shared/action-indicators/services/IndicatorsService';

export const indicatorsStoreInitializer = (ctx: interfaces.Context): IndicatorsStore => {
  const store = ctx.container.get<AppStore>(iocTypes.AppStore);

  @Module({ dynamic: true, store, name: 'IndicatorsStore' })
  class IndicatorsStoreModule extends VuexModule implements IndicatorsStore {
    private _indicatorsList: {
      key: IndicatorsBindingKey;
      value: IndicatorsSet;
    }[] = [];

    public get getIndicators() {
      return (key: IndicatorsBindingKey) => {
        const indicatorsSet = this._indicatorsList.find(i => i.key === key);
        return indicatorsSet ? indicatorsSet.value : new BaseIndicatorsSet();
      };
    }

    @Mutation
    registerIndicators(key: IndicatorsBindingKey) {
      this._indicatorsList.push({
        key,
        value: new BaseIndicatorsSet(),
      });
    }

    @Mutation
    public setLoading(payload: { key: IndicatorsBindingKey; value: boolean }): void {
      const { key, value } = payload;
      const indicatorsSet = this._indicatorsList.find(i => i.key === key);

      if (indicatorsSet) {
        const { loadingsCount } = indicatorsSet.value;
        Vue.set(indicatorsSet.value, 'loadingsCount', loadingsCount + (value ? 1 : -1));
      }
    }

    @Mutation
    public setError(payload: { key: IndicatorsBindingKey; error?: Error }): void {
      const { key, error } = payload;
      const indicatorsSet = this._indicatorsList.find(i => i.key === key);

      if (indicatorsSet) {
        Vue.set(indicatorsSet.value, 'error', error || null);
      }
    }

    @Mutation
    public setSuccess(payload: { key: IndicatorsBindingKey; value: boolean }): void {
      const { key, value } = payload;
      const indicatorsSet = this._indicatorsList.find(i => i.key === key);

      if (indicatorsSet) {
        Vue.set(indicatorsSet.value, 'success', value);
      }
    }
  }

  return getModule(IndicatorsStoreModule);
};
