import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import { routes } from '@/shared/router/consts/routes';

Vue.use(Router);

export const router: Router = new Router({
  routes,
  mode: 'history' as const,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseQuery(query: string): any {
    return qs.parse(query);
  },

  // eslint-disable-next-line @typescript-eslint/ban-types
  stringifyQuery(obj: object): string {
    if (!(obj || Object.keys(obj).length)) {
      return '';
    }
    const query = qs.stringify(obj);
    return query === '' ? '' : `?${query}`;
  },
});
