import { interfaces } from 'inversify';
import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators';
import { IndicatorsService } from '@/shared/action-indicators/services/IndicatorsService';
import { PasswordRecoveryStore } from '@/shared/store/modules/PasswordRecoveryStore';
import { iocTypes } from '@/shared/ioc/types';
import { AppStore } from '@/shared/store/interfaces/AppStore';
import {
  ChangePasswordRequestPayload,
  EntranceHttpClient,
} from '@/shared/api/services/EntranceHttpClient';

export const createPasswordRecoveryStore = (ctx: interfaces.Context): PasswordRecoveryStore => {
  // Dependencies
  const store = ctx.container.get<AppStore>(iocTypes.AppStore);
  const indicatorsService = ctx.container.get<IndicatorsService>(iocTypes.IndicatorsService);
  const entranceHttp = ctx.container.get<EntranceHttpClient>(iocTypes.EntranceHttpClient);

  // Consts
  const indicatorKeys = {
    recoverPassword: Symbol.for('recoverPassword'),
    changePassword: Symbol.for('changePassword'),
  };

  // Store module
  @Module({ dynamic: true, store, name: 'PasswordRecoveryStore', namespaced: true })
  class PasswordRecoveryStoreModule extends VuexModule implements PasswordRecoveryStore {
    get recoverPasswordIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.recoverPassword);
    }

    get changePasswordIndicators() {
      return indicatorsService.getIndicators(indicatorKeys.changePassword);
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.recoverPassword)
    async recoverPassword(payload: { email: string; 'g-recaptcha-response': string | null }) {
      await entranceHttp.resetPassword(payload);
    }

    @Action
    @indicatorsService.bindIndicators(indicatorKeys.changePassword)
    async changePassword(payload: ChangePasswordRequestPayload) {
      await entranceHttp.changePassword(payload);
    }
  }

  return getModule(PasswordRecoveryStoreModule);
};
