import { ExtendedRouteConfig } from '@/shared/router/interfaces/ExtendedRouteConfig';
import Login from '@/pages/login/Index.vue';
import Tfa from '@/pages/tfa/Index.vue';
import ResetPassword from '@/pages/reset-password/Index.vue';
import ChangePassword from '@/pages/change-password/Index.vue';
import Registration from '@/pages/registration/Index.vue';
import ConfirmRegistration from '@/pages/confirm-registration/Index.vue';

export const routes: ExtendedRouteConfig[] = [
  {
    path: '/',
    name: 'default',
    redirect: {
      name: 'login',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      translationKey: 'login__title',
    },
  },
  {
    path: '/login/two-step-verification',
    name: 'login-tfa',
    component: Tfa,
    meta: {
      translationKey: 'tfa__title',
    },
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      translationKey: 'reset_password__title',
    },
  },
  {
    path: '/reset/:token',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      translationKey: 'change_password__title',
    },
  },
  {
    path: '/register',
    name: 'registration',
    component: Registration,
    meta: {
      translationKey: 'registration__title',
    },
  },
  {
    path: '/confirm/:token',
    name: 'confirm-registration',
    component: ConfirmRegistration,
    meta: {
      translationKey: 'confirm_registration__title',
    },
  },
];
