import { createTabEventEmitterFactory } from '@corefy/tab-events/services/implementations/DefaultTabEventEmitter';
import { AppTabEvents } from '../TabEventEmitter';

const emitterFactory = createTabEventEmitterFactory();

/**
 * The map is used to ensure that all event names are set
 */
const eventsMap: { [K in keyof AppTabEvents]: null } = {
  'login-page-initiated': null,
  'dashboard-initiated': null,
  'locale-switch': null,
};

const eventNames = Object.keys(eventsMap) as (keyof AppTabEvents)[];

export const tabEventEmitter = emitterFactory<AppTabEvents>({ eventNames });
